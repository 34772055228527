import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import '../styles/pages/MailingList.css'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class MailingList extends Component {
  state = {
    userContact: {},
    formSubmitted: false,
  }

  handleFormChange = e => {
    const userContact = Object.assign({}, this.state.userContact)
    userContact[e.target.name] = e.target.value
    this.setState({ userContact })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state.userContact,
      }),
    }).then(() => this.setState({ userContact: {}, formSubmitted: true }))
  }

  render() {
    return (
      <Layout>
        <SEO
          title="CONTANGO - MAILING LIST"
          keywords={['Contango Journal', 'Political Theory']}
        />
        <div className="mailingList">
          {!this.state.formSubmitted ? (
            <div>
              <h1>Mailing List</h1>
              <div className="subtext">
                Please provide your contact information below to receive updates
                about future issues and other updates about the Contango
                Journal.
              </div>
              <form
                name="mailingList"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleFormChange}
                />
                <p className="formInput">
                  <label>First Name:</label>
                  <input
                    required={true}
                    type="text"
                    name="firstname"
                    onChange={this.handleFormChange}
                  />
                </p>
                <p className="formInput">
                  <label>Last Name:</label>
                  <input
                    required={true}
                    type="text"
                    name="lastname"
                    onChange={this.handleFormChange}
                  />
                </p>
                <p className="formInput">
                  <label>Email Address:</label>
                  <input
                    required={true}
                    type="email"
                    name="email"
                    onChange={this.handleFormChange}
                  />
                </p>
                <div className="submitContainer">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          ) : (
            <div>
              <h1>Thanks!</h1>
              <p>
                You have been subscribed to receive updates from Contango
                Journal.
              </p>
              <p>
                Until then, explore our latest work and read previously
                published articles <Link to="/issues">here</Link>.
              </p>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}
